@import "utils";

.project-section-container {
  @include glass();
  background-color: rgb(255 255 255 / 80%);
  max-width: 90%;
  margin: 20px auto;
  padding: 20px;

  .project-section {
    display: flex;
    justify-content: space-between;

    &.left {
      flex-direction: row-reverse;

      .content {
        margin-right: 30px;
      }
    }

    &.right {
      flex-direction: row;

      .content {
        margin-left: 30px;
      }
    }

    &.mobile {
      flex-direction: column;

      img {
        width: 100%;
      }

      .content {
        margin-top: 20px;
      }
    }

    img {
      width: 30%;
      height: fit-content;
      margin: auto 0;
      border-radius: 10px;
      box-shadow: 0 0 10px #3c3c3c;
      background-color: white;
    }

    h1 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .rating {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-weight: bold;
      margin: 0 10px;
    }
  }
}
