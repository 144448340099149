html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
}

#app {
  width: 100%;
  background: url("../assets/pattern.png");
  background-repeat: repeat;
  overflow: hidden;
}
