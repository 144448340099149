@import "utils";

#navbar {
  @include glass();
  background-color: white;
  display: flex;
  position: fixed;
  top: 0px;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  z-index: 1000;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;

  &:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0);
    background-color: rgb(240, 240, 240);
  }

  p {
    margin: 0 20px 0 0;
    font-weight: bold;
  }

  a {
    all: unset;
    cursor: pointer;
    margin-right: 20px;

    &:hover {
      color: rgb(92, 92, 92);
      border-bottom: 1px solid rgb(92, 92, 92);
    }
  }
}
